a {
  color: inherit
}

.zd-alert-box {
  bottom: 130px !important;
}

.zd-sticky-button {
  bottom: 80px !important;
}
